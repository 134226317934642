import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {
    ResponseCategories,
    ResponseCategoryBasedOnYourSearches,
    ResponseCategoryHome,
    ResponseFullCategory,
    ResponsePopulateAndPlusCategory,
    ResponseSubcategories,
} from '@app/models/category/response-category.model';
import { DtoFullCategory } from '@app/models/category/dto-full-category.model';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private readonly API_URI = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;
    private http = inject(HttpClient);

    categoryHome(): Observable<ResponseCategoryHome> {
        return this.http.get<ResponseCategoryHome>(
            `${this.API_URI}category/home`,
            { headers: { opc: '2' } }
        );
    }

    subcategories(
        id: string,
        isPublic: boolean = true
    ): Observable<ResponseSubcategories> {
        let params = new HttpParams();
        params = params.append('skip', 0);
        params = params.append('limit', 50);

        const url = isPublic
            ? `${this.API_URI}category/public/fullSubCategory/${id}`
            : `${this.API_URI}category/fullSubCategory/${id}`;

        return this.http.get<ResponseSubcategories>(url, {
            params,
        });
    }

    categories(classification: string): Observable<ResponseCategories> {
        let params = new HttpParams();
        params = params.append('skip', 0);
        params = params.append('limit', 50);
        params = params.append('classification', classification);

        return this.http.get<ResponseCategories>(
            `${this.API_URI}category/fullCategory/`,
            { params, headers: { notSpinner: '1' } }
        );
    }

    populateCategory(
        classification: string
    ): Observable<ResponsePopulateAndPlusCategory> {
        let params = new HttpParams();
        params = params.append('classification', classification);
        return this.http.get<ResponsePopulateAndPlusCategory>(
            `${this.API_URI}category/populate-search`,
            {
                params,
            }
        );
    }

    basedOnYourSearchesCategory(
        classification: string
    ): Observable<ResponseCategoryBasedOnYourSearches> {
        let params = new HttpParams();
        params = params.append('classification', classification);
        return this.http.get<ResponseCategoryBasedOnYourSearches>(
            `${this.API_URI}search-based/categories`,
            { params }
        );
    }

    fullCategory(dto: DtoFullCategory): Observable<ResponseFullCategory> {
        let params = new HttpParams();
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);
        params = params.append('classification', dto.classification);

        return this.http.get<ResponseFullCategory>(
            `${this.API_URI}category/fullCategory`,
            {
                params,
            }
        );
    }
}
